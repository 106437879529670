<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="品目編集" />
    <AppSideMenu />
    <main class="l-main">
      <div class="l-container l-box">
        <ErrorMessage :errMsgs="errMsgs" />
        <div class="c-form__reqTxt l-item">：入力必須</div>
        <div class="l-contents">
          <div class="l-box">
            <h3 class="c-lead icon">品目情報</h3>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>廃棄物種類</label>
            </div>
            <div class="c-inputWrap__items">
              {{ cbaitem.jwnetItemInfo.name }}
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label required">
              <label>CBA品目名称</label>
            </div>
            <div class="c-inputWrap__items">
              <InputText
                :value.sync="editForm.cbaItemName"
                inputTyle="text"
                placeholder="CBA品目名称を入力"
                tooltip="CBA品目名称を入力してください"
                :validation="validateMaxLen"
                :validateArg="50"
                :isValid.sync="validate.editedCba.cbaItemName.isValid"
                :errMsg.sync="validate.editedCba.cbaItemName.errMsg"
              />
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>備考</label>
            </div>
            <div class="c-inputWrap__items">
              <div class="l-area">
                <MyTextarea
                  :value.sync="editForm.remarks"
                  rows="6"
                  placeholder="備考を入力"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="l-btns l-container">
        <div class="left">
          <div class="c-btn secondary small back" @click="backHandler">戻る</div>
        </div>
        <div class="center">
          <div class="c-btn primary mid" @click="formHandler">登録</div>
        </div>
      </div>
    </main>

    <!-- 登録モーダル -->
    <div class="c-modal" v-show="isDisplayConfirm">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body">
          品目を編集します。<br />
          よろしいですか？
        </div>
        <ModalFooterButtons :processing="processing">
          <div class="c-btn secondary small" @click="closeConfirmModalHandler">戻る</div>
          <div class="c-btn primary small" @click="registerHandler">はい</div>
        </ModalFooterButtons>
      </div>
    </div>
    <AppFooter />
  </div>
</template>

<script>
import InputText from "@/components/parts/input/InputText";
import MyTextarea from "@/components/parts/input/MyTextarea";
import validation from "@/mixin/validation";
import formValue from "../mixins/formValue";
import callApi from "../mixins/callApi";
import formValidation from "../mixins/form/formValidation";
import axios from "axios";
import {API_URL} from "@/const";
import ModalFooterButtons from "@/components/layout/ModalFooterButtons";

export default {
  name: "cbaitem-edit",
  components: {
    ModalFooterButtons,
    InputText,
    MyTextarea,
  },
  mixins: [validation, formValue, callApi, formValidation],
  data() {
    return {
      isDisplayConfirm: false,
      errMsgs: [],
      id: 0,
      processing: false,
    };
  },

  methods: {
    closeConfirmModalHandler() {
      this.isDisplayConfirm = false;
    },

    registerHandler() {
      this.errMsgs = [];
      this.processing = true; // 2重submit防止

      axios
        .put(API_URL.WASTEITEM.CBAITEM_EDIT + this.$route.params.id, this.editForm)
        .then(() => {
          this.$router.push("/cbaitem");
        })
        .catch((err) => {
          this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
          this.processing = false; // 2重submit解除
          this.isDisplayConfirm = false;
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
      },

    formHandler() {
      if (!this.checkEdit()) {
        this.processing = false;
        return;
      }
      this.isDisplayConfirm = true;
    },

    // 戻る押下（遷移元に戻る）
    backHandler() {
      this.$router.go(-1);
    },
  },
  mounted() {
    this.id = +this.$route.params.id;
    this.getCbaItemsApi(this.id);
  },
};
</script>
